<template>
  <div class="allotment-lands">
    <form>
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb v-if="allotment && allotment.allotmentId && allotment.name" :items="[
            { route: { name: 'lands-allotments' }, name: 'Lotissements' },
            { route: { name: 'lands-allotments-allotmentId', params: { allotmentId: $route.params.allotmentId } }, name: allotment.name }
          ]" />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <div>
            <h1 v-if="allotment">{{ allotment.name }}</h1>
          </div>
        </div>
        <div class="cell shrink">
          <app-button theme="primary" size="large" icon="add" @click="showModal = true">Ajouter un
            terrain</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav v-if="$route.params.allotmentId" :tabs="tabs" />
      <!-- BODY / LISTE DES TERRAINS -->
      <div class="body">
        <app-table :headers="tableHeaders" :data="lands.data" :loading="isLoading" clickable @line-cliked="handleClickToEdit">
x          <template slot="loading">
            <app-spinner />
          </template>
          <template slot="empty-table">
            <p>Aucune données de disponible</p>
          </template>
          <template slot="lot" slot-scope="{ data }">
              <strong>{{ data.lotNumber }}</strong>
          </template>
          <template slot="diffusion" slot-scope="{ data }">
            <div class="select-container" @click="avoidModalToOpen">
              <app-select id="select-id" v-model="data.isAvailableForAds" @input="shortEditLand(data.landId)"
                :options="[{ label: 'Activée', name: true }, { label: 'Désactivée', name: false }]"/>
            </div>
          </template>
          <template slot="width" slot-scope="{ data }">
            <p>{{ data.width }} m</p>
          </template>
          <template slot="surface" slot-scope="{ data }">
            <p>{{ data.surface }} m2</p>
          </template>
          <template slot="price" slot-scope="{ data }">
            <p class="price-slot">{{ formatCentToEuro(data.price) }} €</p>
          </template>
          <template slot="updatedAt" slot-scope="{ data }">
            <p>{{ $dayjs(data.updatedAt).format('DD/MM/YYYY') }}</p>
          </template>
          <template slot="status" slot-scope="{ data }">
            <div class="select-container" @click="avoidModalToOpen">
              <app-select id="select-id" v-model="data.status"  @input="shortEditLand(data.landId)"
                :options="[{ label: 'Actif', name: 'ACTIVE' }, { label: 'Vendu', name: 'SOLD' }, { label: 'Indisponible', name: 'UNAVAILABLE' }]" />
            </div>
          </template>
          <template slot="details" slot-scope="{ data }">
            <router-link :to="{ name: 'lands-landId', params: { landId: data.landId }}" target="_blank">
              <div @click="avoidModalToOpen">
                <detailsIcon />
              </div>
            </router-link>
          </template>
        </app-table>
        <app-modal class="add-land-modal" :show="showModal" size="medium" :title="landToEdit ? 'Modifier le terrain' : 'Ajouter un terrain'"
          @update:show="showModal = false">
          <form @submit.prevent="landToEdit ? editLand() : addLand()">
            <app-select label="Statut" required v-model="land.status"
              :options="[{ label: 'Actif', name: 'ACTIVE' }, { label: 'Vendu', name: 'SOLD' }, { label: 'Indisponible', name: 'UNAVAILABLE' }]" />
            <div class="input-row">
              <app-input label="Numéro de lot" placeholder="Numéro" v-model="land.lotNumber" type="text" required/>
              <app-input label="Surface (m²)" placeholder="Surface en mètre²" v-model.number="land.surface" type="number"
                :step="0.01" />
            </div>
            <div class="input-row">
              <app-input label="Prix" placeholder="Prix" v-model.number="land.price" type="number" :step="0.01" required/>
              <app-input label="Largeur de façade (m)" placeholder="Largeur en mètre" v-model.number="land.width"
                type="number" :step="0.01"/>
            </div>
            <div class="input-row">
              <app-input
                    label="Profondeur (m)"
                    placeholder="Profondeur en mètre"
                    v-model.number="land.depth"
                    type="number"
                    :step="0.01"
                  />
                <app-select
                    label="Orientation"
                    placeholder="Orientation"
                    :options="orientationOptions"
                    v-model="land.orientation"
                    size="large"
                  />
            </div>
            <div class="image-input-section">
              <p class="section-title">Image</p>
              <app-upload-image :value="imageUrl" @upload="uploadImage" @remove="removeImage" style="min-width: 120px!important;min-height: 40px!important"/>
              <app-label>Fichier .jpg, .png 5Mo max</app-label>
            </div>
            <div class="radio-block">
              <h4>Assainissement</h4>
              <div class="radio-container">
                <app-radio v-model="land.isMainDrainagePlanned" :value="true">
                  Tout à l’égout
                </app-radio>
                <app-radio v-model="land.isMainDrainagePlanned" :value="false">
                  Assainissement autonome
                </app-radio>
              </div>
            </div>
            <div class="radio-block">
              <h4>Viabilisé</h4>
              <div class="radio-container">
                <app-radio v-model="land.isServiced" :value="true">
                  Oui
                </app-radio>
                <app-radio v-model="land.isServiced" :value="false">
                  Non
                </app-radio>
              </div>
            </div>
            <div class="radio-block">
              <h4>Zone argileuse</h4>
              <div class="radio-container">
                <app-radio v-model="land.isClayZone" :value="true">
                  Oui
                </app-radio>
                <app-radio v-model="land.isClayZone" :value="false">
                  Non
                </app-radio>
              </div>
            </div>
            <div class="radio-block">
              <h4>Diffusion (publication en annonce)</h4>
              <div class="radio-container">
                <app-radio v-model="land.isAvailableForAds" :value="true">
                  Oui
                </app-radio>
                <app-radio v-model="land.isAvailableForAds" :value="false">
                  Non
                </app-radio>
              </div>
            </div>
            <app-button theme="primary" size="large" type="submit">{{landToEdit ? 'Modifier le terrain' : 'Ajouter un terrain'}}</app-button>
          </form>
        </app-modal>
      </div>
    </form>
  </div>
</template>

<script>
import allotmentApi from '@/services/api/allotment';
import authApi from '@/services/auth';
import utils from '@/services/utils/utils';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import TabNav from '@/components/layouts/TabNav.vue';
import AppSelect from '@/basics/components/AppSelect.vue';
import landApi from '@/services/api/land';
import orientationOptions from '@/services/data/orientation.json';
import detailsIcon from '@/assets/img/details.svg?inline';

export default {
  name: 'allotment-lands',
  components: {
    Breadcrumb,
    TabNav,
    AppSelect,
    detailsIcon,
  },
  data() {
    return {
      tableHeaders: [
        { label: 'Lot', key: 'lot', size: 2 },
        { label: 'Diffusion/Publication', key: 'diffusion', size: 'auto' },
        { label: 'Façade', key: 'width', size: 'auto' },
        { label: 'Surface', key: 'surface', size: 'auto' },
        { label: 'Prix', key: 'price', size: 'auto' },
        { label: 'Date de mise à jour', key: 'updatedAt', size: 'auto' },
        { label: 'Statut', key: 'status', size: 'auto' },
        { label: 'Détails', key: 'details', size: 'auto' },
      ],
      isLoading: false,
      allotment: null,
      showModal: false,
      orientationOptions,
      limit: 20,
      land: {
        address: {
          addressLine1: null,
          addressLine2: null,
          city: null,
          postalCodeInseeNumber: null,
        },
        allotment: {
          allotmentId: null,
          name: null,
        },
        company: {
          companyId: null,
          name: null,
        },
        coordinates: {
          lat: null,
          long: null,
        },
        allotmentId: null,
        companyId: null,
        depth: null,
        description: null,
        commercialDescription: null,
        excludedRegulations: [],
        excludedStyles: [],
        isAvailableForAds: false,
        isClayZone: false,
        isMainDrainagePlanned: true,
        isServiced: true,
        lotNumber: null,
        orientation: null,
        price: null,
        priceLandAdaptation: null,
        priceLocalRegulation: null,
        reference: null,
        registerReference: null,
        separationDistance: null,
        status: 'UNAVAILABLE',
        surface: null,
        width: null,
      },
      lands: [],
      tabs: [
        {
          route: {
            name: 'lands-allotments-allotmentId',
            params: {
              allotmentId: this.$route.params.allotmentId,
            },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'lands-allotments-allotmentId-lands',
            params: {
              allotmentId: this.$route.params.allotmentId,
            },
          },
          label: 'Terrains',
        },
      ],
      imageUrl: null,
      imageFile: null,
      oldImage: null,
      landToEdit: null,
      brand: null,
      isSelectClicked: false,
    };
  },
  created() {
    this.getBrand();
    this.getAllotment();
    this.getLands();
  },
  watch: {
    showModal() {
      if (!this.showModal) {
        this.landToEdit = null;
        this.land = {
          lotNumber: null,
          width: null,
          surface: null,
          price: null,
          status: 'UNAVAILABLE',
          isMainDrainagePlanned: false,
          isServiced: false,
          isClayZone: false,
          isAvailableForAds: false,
          coordinates: {
            lat: null,
            long: null,
          },
        };
        this.imageUrl = null;
        this.imageFile = null;
        this.oldImage = null;
      }
    },
  },
  methods: {
    async getAllotment() {
      try {
        if (this.$route.params.allotmentId) {
          this.allotment = await allotmentApi.getById(this.$route.params.allotmentId);

          if (this.allotment) {
            this.allotment.separationDistance = this.allotment.separationDistance || this.allotment.separationDistance === 0
              ? this.allotment.separationDistance : this.brand.defaultSeparationDistance;

            if (!this.allotment.excludedStyles) {
              this.allotment.excludedStyles = [];
            }
            if (!this.allotment.excludedRegulations) {
              this.allotment.excludedRegulations = [];
            }
            if (this.allotment.presentationPdf) {
              this.filename = this.allotment.presentationPdf.substring(this.allotment.presentationPdf.lastIndexOf('-') + 1, this.allotment.presentationPdf.lastIndexOf('?'));
            }
          }
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du lotissement.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getLands() {
      try {
        this.isLoading = true;
        this.lands = await landApi.getAll(200, null, null, 'lot', null, null, null, null, null, this.$route.params.allotmentId);
        this.isLoading = false;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des terrains.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    formatCentToEuro(value) {
      return utils.formatCentToEuro(value);
    },
    async getBrand() {
      try {
        this.brand = await authApi.getBrand();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la marque.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    avoidModalToOpen() {
      this.isSelectClicked = true;
      setTimeout(() => {
        this.isSelectClicked = false;
      }, 100);
    },
    async addLand() {
      const { allotment, company, ...land } = this.land;
      try {
        this.isLoading = true;
        const newLand = await landApi.create({
          ...land,
          price: utils.formatEuroToCent(this.land.price),
          allotmentId: this.allotment.allotmentId,
          priceLandAdaptation: this.allotment.priceLandAdaptation,
          priceLocalRegulation: this.allotment.priceLocalRegulation,
          separationDistance: this.allotment.separationDistance,
          address: this.allotment.address,
          excludedRegulations: this.allotment.excludedRegulations,
          excludedStyles: this.allotment.excludedStyles,
          postalCodeInseeNumber: this.allotment.address.postalCodeInseeNumber,
        });
        if (this.imageFile) {
          try {
            const file = new FormData();
            file.append('file', this.imageFile);
            await landApi.uploadImage(file, newLand.landId);
          } catch (er) {
            this.$message.show({
              title: 'Erreur',
              text: 'Il y a eu un problème lors de l\'upload de l\'image',
              cancelText: 'Ok',
              hasCancel: true,
            });
          }
        }
        this.showModal = false;
        this.getLands();
        this.isLoading = false;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la création du terrain.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    goToLand(land) {
      this.$router.push({ name: 'lands-landId', params: { landId: land.landId } });
    },
    uploadImage(event) {
      this.imageFile = event;
      this.imageUrl = URL.createObjectURL(event);
    },
    removeImage() {
      this.imageFile = null;
      this.imageUrl = null;
    },
    async shortEditLand(landId) {
      const landToEdit = this.lands.data.find((land) => land.landId === landId);
      this.landToEdit = { ...landToEdit };
      this.landToEdit.price = utils.formatCentToEuro(this.landToEdit.price);
      this.landToEdit.priceLandAdaptation = utils.formatCentToEuro(this.landToEdit.priceLandAdaptation);
      this.landToEdit.priceLocalRegulation = utils.formatCentToEuro(this.landToEdit.priceLocalRegulation);
      this.landToEdit.separationDistance = this.landToEdit.separationDistance || this.landToEdit.separationDistance === 0 ? this.landToEdit.separationDistance : this.brand.defaultSeparationDistance;
      await this.editLand();
    },
    handleClickToEdit({ landId }) {
      if (this.isSelectClicked) return;
      const landToEdit = this.lands.data.find((land) => land.landId === landId);
      this.landToEdit = { ...landToEdit };
      this.landToEdit.price = utils.formatCentToEuro(this.landToEdit.price);
      this.landToEdit.priceLandAdaptation = utils.formatCentToEuro(this.landToEdit.priceLandAdaptation);
      this.landToEdit.priceLocalRegulation = utils.formatCentToEuro(this.landToEdit.priceLocalRegulation);
      this.landToEdit.separationDistance = this.landToEdit.separationDistance || this.landToEdit.separationDistance === 0 ? this.landToEdit.separationDistance : this.brand.defaultSeparationDistance;
      this.land = this.landToEdit;
      this.imageUrl = this.landToEdit.landImage;
      this.oldImage = this.landToEdit.landImage;
      this.showModal = true;
    },
    async editLand() {
      this.isLoading = true;
      const editedLand = JSON.parse(JSON.stringify(this.landToEdit));
      editedLand.postalCodeInseeNumber = this.landToEdit.address.postalCodeInseeNumber;
      delete editedLand.address.postalCodeInseeNumber;
      delete editedLand.company;
      delete editedLand.allotment;
      delete editedLand.isCompanyRegistered;
      editedLand.companyId = null;
      try {
        await landApi.update(this.landToEdit.landId, {
          ...editedLand,
          price: utils.formatEuroToCent(editedLand.price),
          priceLandAdaptation: utils.formatEuroToCent(editedLand.priceLandAdaptation),
          priceLocalRegulation: utils.formatEuroToCent(editedLand.priceLocalRegulation),
        });
        if (this.oldImage !== this.imageUrl) {
          try {
            const file = new FormData();
            file.append('file', this.imageFile);
            await landApi.uploadImage(file, this.landToEdit.landId);
          } catch (er) {
            this.$message.show({
              title: 'Erreur',
              text: 'Il y a eu un problème lors de l\'upload de l\'image',
              cancelText: 'Ok',
              hasCancel: true,
            });
          }
        }
        this.showModal = false;
        this.getLands();
        this.isLoading = false;
        this.$notification.show({ text: 'Ce terrain a été mis à jour avec succès !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la mise à jour du terrain',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang="sass">
.allotment-lands
  .body
    @include screen
    .options
      min-height: 200px
      .data
        flex: auto
    .app-table
      .data
        overflow: visible
      .price-slot
        color: $primary
        font-weight: bold
    .app-modal .modal-wrapper
      overflow: scroll
      .app-select
        width: 10rem
      .input-row
        display: flex
        flex-direction: row
        justify-content: space-between
        gap: 24px
        margin-top: 12px
        .app-input
          width: 50%
        .app-select
          width: 50%
      .image-input-section
        margin-top: 12px
        .section-title
          font-size: 14px
          font-weight: 500
          color: $primary
          margin-bottom: 3px
          line-height: 15px
        .app-upload-image
          margin-bottom: 6px
          width: 130px
          height: 80px
        .app-label
          color: $info
      .radio-block
        margin-top: 12px
        .radio-container
          display: flex
          flex-direction: row
          justify-content: flex-start
          align-items: center
          gap: 24px
          margin-top: 12px
      .app-button
        margin: auto
  form > .header
    @include header
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
</style>
